/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.css"
import "./index.css"

const Layout = ({ children }) => <Main>{children}</Main>

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Main = styled.main`
  overflow-x: hidden;
`

export default Layout
